

































































































































































































































import {Component, Vue} from "vue-property-decorator";
import {STATE_LIST} from "@/constants/ProfileConstants";
import {namespace} from "vuex-class";
import SaveIT2104RequestDTO from "@/dto/archive/SaveIT2104RequestDTO";
import DocumentService from "@/services/DocumentService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import RegexUtils from "@/utils/RegexUtils";
import PortalInput from "@/components/common/PortalInput.vue";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import {InputType} from "@/components/common/InputType";
import {ifValid} from "@/utils/ComponentUtils";
import RouteNames from "@/router/RouteNames";
import { FillableDocumentStatus } from "@/dto/archive/FillableDocumentStatus";

const AppModule = namespace("App");

@Component({
	computed: {
		InputType() {
			return InputType
		},
		AutoCompleteDTO() {
			return AutoCompleteDTO
		}
	},
	components: {AutoComplete, PortalInput}
})
export default class FormIT2104 extends Vue {

	private successful = false;

	private message = "";

	private states = STATE_LIST

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private exists = true;

	private processed = false;

	private request = new SaveIT2104RequestDTO();

  public created(): void {
    this.request.single = true;
    this.request.nycResident = false;
    this.request.yonkersResident = false;
    if (this.$route.name === RouteNames.PUBLIC_FORMS_IT2104) {
      this.loadPublicForm();
    } else{
      this.loadInternalForm();
    }
  }

  private loadPublicForm(): void{
    this.startLoading();
    DocumentService.loadPreparedPublicFormIT2104(this.$route.params.publicId).then(
      success => {
        if (success.data.status === FillableDocumentStatus.PENDING_SIGNATURE) {
          this.goToSignature(success.data.signerPublicId as string);
        } else {
          this.request.merge(success.data);
        }
        this.stopLoading();
      },
      error => {
        this.exists = false;
        this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        this.successful = false;
        this.stopLoading();
      });
  }

  private loadInternalForm(): void{
    this.startLoading();
    DocumentService.loadPreparedFormIT2104(Number.parseInt(this.$route.params.id)).then(
      success => {
        this.request.merge(success.data);
        this.stopLoading();
      },
      error => {
        this.exists = false;
        this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        this.successful = false;
        this.stopLoading();
      });
  }

  private save(): void {
    if (this.$route.name === RouteNames.PUBLIC_FORMS_IT2104) {
      this.savePublicForm();
    } else {
      this.saveInternalForm();
    }
  }

  private saveInternalForm(): void {
    ifValid(this, () => {
      this.message = "";
      this.startLoading();
      DocumentService.saveIT2104(Number.parseInt(this.$route.params.id), this.request).then(
        success => {
          this.successful = true;
          this.processed = true;
          this.$router.push({name: RouteNames.ARCHIVE_IT2104});
        },
        error => {
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        }
      ).then(() => this.stopLoading());
    })
  }

  private savePublicForm(): void {
    ifValid(this, () => {
      this.message = "";
      this.startLoading();
      DocumentService.savePublicIT2104(this.$route.params.publicId, this.request).then(
        success => {
          this.successful = true;
          this.processed = true;
          this.goToSignature(success.data);
        },
        error => {
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        }
      ).then(() => this.stopLoading());
    })
  }

	ensureExists(): void {
		this.startLoading();
		DocumentService.existsByPublicId(this.$route.params.publicId).then(
			success => this.exists = success.data,
			error => this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string
		).then(() => this.stopLoading());
	}

	onSingle(): void {
		this.request.single = true;
		this.request.married = false;
		this.request.higherRate = false;
	}

	onMarried(): void {
		this.request.single = false;
		this.request.married = true;
		this.request.higherRate = false;
	}

	onHigherRate(): void {
		this.request.single = false;
		this.request.married = false;
		this.request.higherRate = true;
	}

	handleSsn(event: KeyboardEvent): void {
		let current = this.request.ssn ? this.request.ssn : "";
		if (RegexUtils.testSsnPreSeparator(current)) {
			current = current.concat('-')
			this.request.ssn = current;
		}
		const next = (current).concat(event.key)
		if (!RegexUtils.testSsn(next)) {
			event.preventDefault();
		}
	}

  private goToSignature(publicId: string): void {
    this.$router.push({name: RouteNames.SIGN_PUBLIC, params: { publicId }});
  }

}
